import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import logoIcon from "../images/logo_white.png";
import axios from "axios";
import Loading from "../helpers/loading";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button"

const API_URL = "https://api.tookfresh.com";

const header = {
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
};
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
  },
  bar: {
    height: 80,
    width: "100%",
    backgroundColor: "#000",
    paddingTop: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },  
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    width: "100%",
    height: 50,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: "#707070",
    textAlign: "center",
  },
  routeButton: {
    textTransform: 'none',
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.primary.main,
    fontSize: '1.5em',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  route: {
    textDecoration: "none", 
    marginTop: theme.spacing(3)
  },
  checkEmail: {
    marginTop: theme.spacing(2)
  }
});

const UserConfirmation = ({ classes, intl }) => {
  const [response, setResponse] = useState("none");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    registerResponse();
    error && setError(false)
  }, [window.location.pathname]);

  const getLabel = (labelId) => {
    const { formatMessage } = intl;
    return formatMessage({ id: labelId });
  };

  const registerResponse = () => {
    let pathname = window.location.pathname;   
    let step1 = pathname.replace('/store', '')
    let step2 = step1?.replace('verification', 'confirmation')
    setLoading(true);
    axios
      .post(`${API_URL}${step2}`, header)
      .then((response) => {        
        if (response.status === 200) {
           setLoading(false);
           setResponse(response.data)
           error && setError(false)
        }
      })
      .catch((error) => {        
        if (error) {
          setError((true))
          setLoading(false);
          setResponse(getLabel('expired-token'));
        }
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <img src={logoIcon} alt="TookFresh" height="45" />
      </div>
      {loading ? <Loading open={loading} /> : (
        <Container maxWidth="md" className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography variant="h4" className={classes.header}>
            {response}
          </Typography>
          <a className={classes.route} href="https://web.tookfresh.com/merchant/signin"  >
              <Button className={classes.routeButton} variant='outlined' disableRipple>
                  {getLabel('return-to-login-page')}
              </Button>
          </a>
          {!error && <Typography className={classes.checkEmail}> {getLabel('check-your-email')}</Typography>}
        </div>
      </Container>
      )}
    </div>
  );
};

export default withStyles(styles)(withRouter(injectIntl(UserConfirmation)));
