import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router-dom";
import { injectIntl } from "react-intl";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import logoIcon from "../images/logo_white.png";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { alertActions } from "../actions/alert.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { generalConstants } from "../constants/general.constants";
import ResponseScreen from "./response_screen";
import Loading from "../helpers/loading";
import OrderRejectConfirmation from "./store_order_confirm/order_reject_confirmation";

const API_URL = "https://api.tookfresh.com";

const header = {
  data: {},
  headers: {
    "Content-Type": "application/json",
  },
};

const flexColumnCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const styles = () => ({
  root: {
    ...flexColumnCenter,
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
  },
  bar: {
    ...flexColumnCenter,
    height: 80,
    width: "100%",
    backgroundColor: "#000",
    paddingTop: 15,
  },
  container: {
    ...flexColumnCenter,
    width: "100%",
    height: "100%",
    justifyContent: "center"
  },
  headerContainer: {
    width: "100%",
    height: 50,
    marginBottom: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: "#707070",
    textAlign: "center",
  },
  buttonContainer: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
  },
  imageContainer: {
    width: "100%",
    height: 300,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    backgroundColor: "pink",
  },
  approveContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cancelContainer: {
    width: "50%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  approve: {
    width: "90%",
    height: "100%",
    backgroundColor: "#00c853",
    color: "#ffffff",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#00c853",
    },
  },
  cancel: {
    width: "90%",
    height: "100%",
    backgroundColor: "#e53935",
    color: "#ffffff",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#e53935",
    },
  },
});

const StoreOrderConfirm = ({ intl, classes, showError }) => {
  const { params } = useRouteMatch();
  const [response, setResponse] = useState("none");
  const [errorMessage, setErrorMessage] = useState(null);
  const [linkValid, setLinkValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);

  useEffect(() => {
    token && isLinkValid();
  }, [token]);

  useEffect(() => {
    if (!params) return;
    const urlToken = params.token;
    urlToken && setToken(urlToken);
  }, [params]);

  const getLabel = (labelId) => {
    const { formatMessage } = intl;
    return formatMessage({ id: labelId });
  };

  const isLinkValid = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/order/verification/email/ui?token=${token}`, header)
      .then((response) => {
        if (response.status === 200) {
          setLinkValid(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data?.error_message);
          setLinkValid(false);
          setLoading(false);
        }
      });
  };

  const onConfirm = (e) => {
    setLoading(true);
    e.preventDefault();
    axios
      .post(
        `${API_URL}/order/confirmation/email/ui`,
        {
          token,
          orderStatus: generalConstants.APPROVED,
        },
        header
      )
      .then(
        (res) => {
          if (res.status === 200) setResponse("approved");
          setLoading(false);
        },
        (error) => {
          showError(error.response?.data?.error_message?.toString());
          setLoading(false);
        }
      );
  };

  const toggleRejectConfirmation = () =>
    setOpenRejectConfirmation((prev) => !prev);

  const onReject = () => {
    setLoading(true);
    axios
      .post(
        `${API_URL}/order/confirmation/email/ui`,
        {
          token,
          orderStatus: generalConstants.CANCELED,
        },
        header
      )
      .then(
        (res) => {
          if (res.status === 200) setResponse("canceled");
          setLoading(false);
        },
        (error) => {
          showError(error.response?.data?.error_message?.toString());
          setLoading(false);
        }
      );
  };

  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        <img src={logoIcon} alt="TookFresh" height="45" />
      </div>
      <Loading open={loading} />
      {linkValid ? (
        response === "none" ? (
          <>
            <Container maxWidth="sm" className={classes.container}>
              <div className={classes.headerContainer}>
                <Typography variant="h5" className={classes.header}>
                  {getLabel("action-for-order")}
                </Typography>
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.approveContainer}>
                  <Button
                    variant="contained"
                    className={classes.approve}
                    onClick={(e) => onConfirm(e)}
                  >
                    {getLabel("approve-label")}
                  </Button>
                </div>
                <div className={classes.cancelContainer}>
                  <Button
                    variant="contained"
                    className={classes.cancel}
                    onClick={toggleRejectConfirmation}
                  >
                    {getLabel("reject-label")}
                  </Button>
                </div>
              </div>
            </Container>
            <OrderRejectConfirmation
              open={openRejectConfirmation}
              onClose={toggleRejectConfirmation}
              onReject={onReject}
            />
          </>
        ) : (
          <ResponseScreen res={response} />
        )
      ) : (
        <Container maxWidth="md" className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography variant="h4" className={classes.header}>
              {errorMessage}
            </Typography>
          </div>
        </Container>
      )}
    </div>
  );
};

const actionCreators = {
  showError: alertActions.error,
};

export default withStyles(styles)(
  withRouter(injectIntl(connect(null, actionCreators)(StoreOrderConfirm)))
);
