import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

export const RedButton = withStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    color: "white",
    height: 48,
    borderRadius: 10,
    fontSize: 18,
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "none",
    },
  },
}))(Button);

export const BlackButton = withStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    height: 48,
    borderRadius: 10,
    fontSize: 16,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.31,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
    backgroundColor: "black",
    boxShadow: "none",
    "&:disabled": {
      backgroundColor: "black",
      color: "gray",
    },
    "&:hover": {
      backgroundColor: "black",
    },
    "&:focus": {
      backgroundColor: "black",
      boxShadow: "none",
    },
  },
}))(Button);

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

