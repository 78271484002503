import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { history } from './helpers/history';
import StoreOrderConfirm from './components/store_order_confirm'
import NoMatch from './components/no_match';
import UserConfirmation from './components/user_confirmation'

const styles = theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },

});

const Home = ({classes}) => {
return (
    <div className={classes.root} >
        <Router history={history}>
            <Switch>
                {/* <Redirect  from="/" to={window.location.pathname.includes('order') ? "/order/confirmation/:token?" : "/store/team/invite/confirmation/:token?/ui"} /> */}
                <Route exact path="/order/confirmation/email/:token?" component={StoreOrderConfirm} />
                <Route exact path="/store/team/invite/verification/:token?/ui" component={UserConfirmation} />
                <Route path="*" component={NoMatch} />
            </Switch>
        </Router>
    </div>
);
    
}

export default withStyles(styles)(
    injectIntl(
        (Home)
    )
);