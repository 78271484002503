export const messages = {
    en: {        
        'success-label':'Success',
        'action-for-order': 'Please take your action for the order',
        'approve-label': 'Approve',
        'cancel-label': 'Cancel',
        'reject-label': 'Reject',
        'order-approved': 'The order has been approved',
        'order-canceled': 'The order has been canceled',
        'order-rejected': 'The order has been rejected',
        'return-to-login-page': 'Return to Login Page',
        'check-your-email': "If you don't have any Tookfresh account yet, please check your email for the initial password information.",
        'expired-token': "This link can not be used anymore!",
        'are-you-sure': 'Are you sure?',
        'about-to-reject': 'You are about to reject incoming order!',
    }
};