import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    width: "100%",
    height: 50,
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    color: "#707070",
    textAlign: "center",
  },
  imageContainer: {
    width: "100%",
    height: 300,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    backgroundColor: "pink",
  },
});

const ResponseScreen = ({ classes, res, intl }) => {
  const getLabel = (labelId) => {
    const { formatMessage } = intl;
    return formatMessage({ id: labelId });
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.header}>
          {getLabel(res === "approved" ? "order-approved" : "order-rejected")}
        </Typography>
      </div>
    </Container>
  );
};

export default withStyles(styles)(withRouter(injectIntl(ResponseScreen)));
